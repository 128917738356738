import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { tr } from './translations/tr';
import { en } from './translations/en';
import { ar } from './translations/ar';

const resources = {
    tr: { translation: tr },
    en: { translation: en },
    ar: { translation: ar }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'tr',
        fallbackLng: 'tr',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n; 