import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsMenuOpen(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo-container" onClick={scrollToTop}>
                    <img 
                        src="/assets/logo.jpg"
                        alt="NEOPLUS SOFTWARE" 
                        className="company-logo" 
                    />
                </div>

                <div className="nav-items">
                    <ul className="nav-links">
                        <li><a href="#services">{t('nav.services')}</a></li>
                        <li><a href="#about">{t('nav.about')}</a></li>
                        <li className="company-name">
                            <span className="neoplus">NEOPLUS</span>
                            <span className="software">SOFTWARE</span>
                        </li>
                        <li><a href="#testimonials">{t('nav.testimonials')}</a></li>
                        <li><a href="#contact">{t('nav.contact')}</a></li>
                    </ul>
                </div>

                <div className="language-selector">
                    <button 
                        onClick={() => changeLanguage('tr')} 
                        className={`language-button ${i18n.language === 'tr' ? 'active' : ''}`}
                    >
                        TR
                    </button>
                    <button 
                        onClick={() => changeLanguage('en')} 
                        className={`language-button ${i18n.language === 'en' ? 'active' : ''}`}
                    >
                        EN
                    </button>
                    <button 
                        onClick={() => changeLanguage('ar')} 
                        className={`language-button ${i18n.language === 'ar' ? 'active' : ''}`}
                    >
                        AR
                    </button>
                </div>

                {/* Mobil menü butonu */}
                <div className="mobile-menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {/* Mobil menü */}
                <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
                    <ul>
                        <li><a href="#services" onClick={() => setIsMenuOpen(false)}>{t('nav.services')}</a></li>
                        <li><a href="#about" onClick={() => setIsMenuOpen(false)}>{t('nav.about')}</a></li>
                        <li><a href="#testimonials" onClick={() => setIsMenuOpen(false)}>{t('nav.testimonials')}</a></li>
                        <li><a href="#contact" onClick={() => setIsMenuOpen(false)}>{t('nav.contact')}</a></li>
                    </ul>
                    <div className="mobile-language-selector">
                        <button onClick={() => changeLanguage('tr')}>TR</button>
                        <button onClick={() => changeLanguage('en')}>EN</button>
                        <button onClick={() => changeLanguage('ar')}>AR</button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar; 