export const tr = {
    nav: {
        home: "Ana Sayfa",
        about: "Biz Kimiz",
        services: "Neler Yapıyoruz",
        testimonials: "Referanslar",
        contact: "İletişim"
    },
    hero: {
        subtitle: "ile",
        title: "Dijital Dönüşümün Öncüsü Olun",
        description: "Yapay zeka, veri analitiği ve yenilikçi yazılım çözümlerimizle işletmenizi geleceğe hazırlıyoruz. AR-GE odaklı yaklaşımımız ve uzman ekibimizle teknolojinin sınırlarını zorluyoruz.",
        button: "💡 Geleceği Birlikte Şekillendirelim"
    },
    about: {
        title: "Hakkımızda",
        history: {
            title: "Şirketin Tarihçesi ve Kuruluş Amacı",
            description: "2024 yılında yazılım dünyasına yenilikçi çözümler sunmak amacıyla kurulan NEOPLUS SOFTWARE, teknoloji dünyasında devrim yaratmaya hazırlanıyor. Geleceği şekillendiren vizyonumuzla, her müşterimiz için özel çözümler üretiyoruz."
        },
        mission: {
            title: "Misyon ve Vizyon",
            description: "Misyonumuz, en son teknolojileri kullanarak işletmelerin dijital dönüşümüne öncülük etmek. Vizyonumuz, global teknoloji sahnesinde Türkiye'nin gururla parlayan yıldızı olmak ve dünya standartlarında yazılım çözümleri sunmak."
        },
        expertise: {
            title: "Şirketin Uzmanlık Alanları",
            description: "Yapay zeka, blockchain, bulut teknolojileri ve veri analitiği alanlarında uzmanlaşmış ekibimizle geleceğin teknolojilerini bugünden sunuyoruz. Her sektöre özel, yenilikçi ve sürdürülebilir çözümler geliştiriyoruz."
        },
        values: {
            title: "Değerler ve İlkeler",
            description: "İnovasyon, mükemmellik ve güven temel değerlerimizdir. Müşteri memnuniyeti odaklı yaklaşımımız ve etik iş anlayışımızla, teknoloji dünyasında güvenilir bir çözüm ortağı olarak yer alıyoruz."
        },
        team: {
            title: "Ekip ve Çalışma Kültürü",
            description: "Alanında uzman, yenilikçi ve dinamik ekibimizle sürekli gelişimi hedefliyoruz. Yaratıcı fikirlere değer veren, işbirlikçi ve özgür çalışma ortamımızla teknolojinin sınırlarını zorluyoruz."
        },
        contact: {
            title: "İletişim Çağrısı",
            description: "Dijital dönüşüm yolculuğunuzda yanınızda olmak için sabırsızlanıyoruz! Geleceği birlikte şekillendirmek için hemen iletişime geçin ve teknolojinin sunduğu sınırsız olanaklardan faydalanın."
        }
    },
    services: {
        title: "Hizmetlerimiz",
        items: {
            web: {
                title: "Web Geliştirme",
                description: "Etkileyici ve modern web siteleri, e-ticaret platformları ve özel web uygulamaları geliştiriyoruz. Responsive tasarım ve en son teknolojilerle hayalinizdeki projeyi gerçeğe dönüştürüyoruz."
            },
            mobile: {
                title: "Mobil Uygulama",
                description: "iOS ve Android için yüksek performanslı, kullanıcı dostu mobil uygulamalar geliştiriyoruz. Native ve cross-platform çözümlerle mobil dünyada yerinizi alın."
            },
            consulting: {
                title: "Yazılım Danışmanlığı",
                description: "Dijital dönüşüm sürecinizde size özel stratejiler geliştiriyor, teknik danışmanlık hizmetleri sunuyoruz. Teknoloji yol haritanızı birlikte çizelim."
            }
        }
    },
    contact: {
        title: "İletişim",
        address: {
            title: "Adres",
            value: "Al Asayel St, Sobha Ivory-2, No:606 Dubai/UAE"
        },
        email: {
            title: "E-posta",
            value: "info@neoplussoftware.com"
        },
        phone: {
            title: "Telefon",
            value: "+971586211306"
        }
    }
}; 