export const ar = {
    nav: {
        home: "الصفحة الرئيسية",
        about: "من نحن",
        services: "خدماتنا",
        testimonials: "الشهادات",
        contact: "اتصل بنا"
    },
    hero: {
        subtitle: "مع",
        title: "كن رائداً في التحول الرقمي",
        description: "نقوم بإعداد عملك للمستقبل من خلال حلول الذكاء الاصطناعي وتحليلات البيانات والبرمجيات المبتكرة.",
        button: "💡 لنشكل المستقبل معاً"
    },
    about: {
        title: "من نحن",
        history: {
            title: "تاريخ الشركة وهدف التأسيس",
            description: "تأسست NEOPLUS SOFTWARE في عام 2024 وتستعد لإحداث ثورة في عالم التكنولوجيا من خلال تقديم حلول مبتكرة. مع رؤيتنا التي تشكل المستقبل، نقوم بإنشاء حلول مخصصة لكل عميل."
        },
        mission: {
            title: "المهمة والرؤية",
            description: "مهمتنا هي قيادة الشركات في التحول الرقمي باستخدام أحدث التقنيات. رؤيتنا هي أن نصبح نجم تركيا المتألق في المشهد التكنولوجي العالمي وتقديم حلول برمجية عالمية المستوى."
        },
        expertise: {
            title: "مجالات الخبرة",
            description: "مع فريقنا المتخصص في الذكاء الاصطناعي وتقنية البلوكتشين والتقنيات السحابية وتحليل البيانات، نقدم تقنيات الغد اليوم. نطور حلولاً مبتكرة ومستدامة مخصصة لكل قطاع."
        },
        values: {
            title: "القيم والمبادئ",
            description: "الابتكار والتميز والثقة هي قيمنا الأساسية. من خلال نهجنا المتمحور حول العملاء وفهمنا للأعمال الأخلاقية، نضع أنفسنا كشريك حلول موثوق به في عالم التكنولوجيا."
        },
        team: {
            title: "الفريق وثقافة العمل",
            description: "مع فريقنا من الخبراء المبتكرين والديناميكيين، نهدف إلى التطوير المستمر. ندفع حدود التكنولوجيا من خلال بيئة عملنا التعاونية والحرة التي تقدر الأفكار الإبداعية."
        },
        contact: {
            title: "دعوة للتواصل",
            description: "نحن متحمسون لنكون إلى جانبكم في رحلة التحول الرقمي! تواصل معنا الآن لتشكيل المستقبل معًا والاستفادة من الإمكانيات غير المحدودة التي تقدمها التكنولوجيا."
        }
    },
    services: {
        title: "خدماتنا",
        items: {
            web: {
                title: "تطوير الويب",
                description: "نقوم بإنشاء مواقع ويب مؤثرة وحديثة ومنصات للتجارة الإلكترونية وتطبيقات ويب مخصصة. نحول مشروع أحلامك إلى حقيقة من خلال التصميم المتجاوب وأحدث التقنيات."
            },
            mobile: {
                title: "تطوير تطبيقات الموبايل",
                description: "نطور تطبيقات موبايل عالية الأداء وسهلة الاستخدام لنظامي iOS و Android. احجز مكانك في عالم الموبايل من خلال الحلول الأصلية ومتعددة المنصات."
            },
            consulting: {
                title: "استشارات البرمجيات",
                description: "نطور استراتيجيات مخصصة ونقدم خدمات استشارية تقنية لرحلة التحول الرقمي الخاصة بك. دعنا نرسم خريطة طريق التكنولوجيا معًا."
            }
        }
    },
    contact: {
        title: "اتصل بنا",
        address: {
            title: "العنوان",
            value: "شارع العسايل، صبحا آيفوري-2، رقم:606 دبي/الإمارات"
        },
        email: {
            title: "البريد الإلكتروني",
            value: "info@neoplussoftware.com"
        },
        phone: {
            title: "الهاتف",
            value: "+971586211306"
        }
    }
}; 