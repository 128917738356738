import React from 'react';
import { useTranslation } from 'react-i18next';
import './Services.css';

const Services = () => {
    const { t } = useTranslation();

    const services = [
        {
            id: 'web',
            icon: (
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M3 12h18M3 6h18M3 18h18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            )
        },
        {
            id: 'mobile',
            icon: (
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            )
        },
        {
            id: 'consulting',
            icon: (
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15l-3-3m0 0l3-3m-3 3h8M5 12a7 7 0 1014 0 7 7 0 00-14 0z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            )
        }
    ];

    return (
        <section id="services" className="services-section">
            <h2 className="services-title">{t('services.title')}</h2>
            <div className="services-grid">
                {services.map((service) => (
                    <div key={service.id} className="card">
                        <div className="service-content">
                            <div className="service-icon">
                                {service.icon}
                            </div>
                            <h3 className="service-title">
                                {t(`services.items.${service.id}.title`)}
                            </h3>
                            <p className="service-description">
                                {t(`services.items.${service.id}.description`)}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;