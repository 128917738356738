import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutSection.css';

function AboutSection() {
    const { t } = useTranslation();

    return (
        <div className="about-container">
            <h2 className="about-title">{t('about.title')}</h2>
            <div className="about-grid">
                <div className="about-card">
                    <div className="card-icon history-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M12 8v4l3 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                            <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2"/>
                        </svg>
                    </div>
                    <h3>{t('about.history.title')}</h3>
                    <p>
                        {t('about.history.description').split('NEOPLUS SOFTWARE').map((text, i, arr) => (
                            <React.Fragment key={i}>
                                {text}
                                {i < arr.length - 1 && <span className="neoplus-software-text">NEOPLUS SOFTWARE</span>}
                            </React.Fragment>
                        ))}
                    </p>
                </div>

                <div className="about-card">
                    <div className="card-icon mission-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M12 2l-5.5 9h11L12 2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.5 11L12 20l-5.5-9h11z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h3>{t('about.mission.title')}</h3>
                    <p>{t('about.mission.description')}</p>
                </div>

                <div className="about-card">
                    <div className="card-icon expertise-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22 4L12 14.01l-3-3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h3>{t('about.expertise.title')}</h3>
                    <p>{t('about.expertise.description')}</p>
                </div>

                <div className="about-card">
                    <div className="card-icon values-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h3>{t('about.values.title')}</h3>
                    <p>{t('about.values.description')}</p>
                </div>

                <div className="about-card">
                    <div className="card-icon team-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M16 21v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                            <circle cx="9" cy="7" r="4" stroke="currentColor" strokeWidth="2"/>
                            <path d="M22 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <h3>{t('about.team.title')}</h3>
                    <p>{t('about.team.description')}</p>
                </div>

                <div className="about-card">
                    <div className="card-icon contact-icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" fill="none">
                            <path d="M15 10l5 5-5 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                            <path d="M4 4v7a4 4 0 004 4h12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <h3>{t('about.contact.title')}</h3>
                    <p>{t('about.contact.description')}</p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection; 