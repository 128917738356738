export const en = {
    nav: {
        home: "Home",
        about: "About Us",
        services: "Services",
        testimonials: "Testimonials",
        contact: "Contact"
    },
    hero: {
        subtitle: "with",
        title: "Lead Digital Transformation",
        description: "We prepare your business for the future with our AI, data analytics and innovative software solutions. We push the boundaries of technology with our R&D-focused approach and expert team.",
        button: "💡 Shape the Future Together"
    },
    about: {
        title: "About Us",
        history: {
            title: "Company History & Purpose",
            description: "Founded in 2024, NEOPLUS SOFTWARE is preparing to revolutionize the technology world by offering innovative solutions. With our future-shaping vision, we create custom solutions for each of our clients."
        },
        mission: {
            title: "Mission & Vision",
            description: "Our mission is to lead businesses in digital transformation using cutting-edge technologies. Our vision is to become Turkey's proudly shining star in the global technology scene and provide world-class software solutions."
        },
        expertise: {
            title: "Areas of Expertise",
            description: "With our team specialized in AI, blockchain, cloud technologies, and data analytics, we deliver tomorrow's technologies today. We develop innovative and sustainable solutions customized for each sector."
        },
        values: {
            title: "Values & Principles",
            description: "Innovation, excellence, and trust are our core values. With our customer-centric approach and ethical business understanding, we position ourselves as a reliable solution partner in the technology world."
        },
        team: {
            title: "Team & Work Culture",
            description: "With our expert, innovative, and dynamic team, we aim for continuous development. We push the boundaries of technology with our collaborative and free work environment that values creative ideas."
        },
        contact: {
            title: "Contact Call",
            description: "We're eager to be by your side in your digital transformation journey! Get in touch now to shape the future together and benefit from the unlimited possibilities that technology offers."
        }
    },
    services: {
        title: "Our Services",
        items: {
            web: {
                title: "Web Development",
                description: "We create impressive and modern websites, e-commerce platforms, and custom web applications. We bring your dream project to life with responsive design and cutting-edge technologies."
            },
            mobile: {
                title: "Mobile Development",
                description: "We develop high-performance, user-friendly mobile applications for iOS and Android. Take your place in the mobile world with native and cross-platform solutions."
            },
            consulting: {
                title: "Software Consulting",
                description: "We develop custom strategies and provide technical consulting services for your digital transformation journey. Let's chart your technology roadmap together."
            }
        }
    },
    contact: {
        title: "Contact",
        address: {
            title: "Address",
            value: "Al Asayel St, Sobha Ivory-2, No:606 Dubai/UAE"
        },
        email: {
            title: "Email",
            value: "info@neoplussoftware.com"
        },
        phone: {
            title: "Phone",
            value: "+971586211306"
        }
    }
}; 