import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

function Contact() {
    const { t } = useTranslation();

    return (
        <section id="contact" className="contact-section">
            <h2 className="contact-title">{t('contact.title')}</h2>
            <div className="contact-container">
                <div className="contact-content">
                    <div className="contact-info">
                        <div className="info-item">
                            <div className="icon-wrapper">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" stroke="currentColor" strokeWidth="2"/>
                                    <circle cx="12" cy="10" r="3" stroke="currentColor" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="info-content">
                                <h3>{t('contact.address.title')}</h3>
                                <p>{t('contact.address.value')}</p>
                            </div>
                        </div>

                        <div className="info-item">
                            <div className="icon-wrapper">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2m20 0l-10 7L2 6m20 0v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6" stroke="currentColor" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="info-content">
                                <h3>{t('contact.email.title')}</h3>
                                <p>{t('contact.email.value')}</p>
                            </div>
                        </div>

                        <div className="info-item">
                            <div className="icon-wrapper">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72c.127.96.361 1.903.7 2.81a2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45c.907.339 1.85.573 2.81.7A2 2 0 0122 16.92z" stroke="currentColor" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="info-content">
                                <h3>{t('contact.phone.title')}</h3>
                                <p>{t('contact.phone.value')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="contact-map">
                        <div className="map-container">
                            <iframe 
                                title="location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.8876811443966!2d55.2667!3d25.1667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDEwJzAwLjEiTiA1NcKwMTYnMDAuMSJF!5e0!3m2!1sen!2s!4v1635959562000!5m2!1sen!2s" 
                                width="100%" 
                                height="100%" 
                                style={{border:0}} 
                                allowFullScreen="" 
                                loading="lazy">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact; 