import React, { useState, useEffect } from 'react';
import './Testimonials.css';

function Testimonials() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const testimonials = [
        {
            name: "A. Y.",
            company: "Tech Solutions Inc.",
            role: "CEO",
            text: "NEOPLUS SOFTWARE ile çalışmak muhteşem bir deneyimdi. Projemizi zamanında ve bütçe dahilinde tamamladılar. Profesyonel yaklaşımları ve teknik uzmanlıkları gerçekten etkileyici.",
            rating: 5
        },
        {
            name: "M. K.",
            company: "Digital Ventures",
            role: "CTO",
            text: "Yapay zeka projemizde NEOPLUS SOFTWARE'in sunduğu çözümler beklentilerimizin ötesindeydi. Teknik bilgileri ve müşteri odaklı yaklaşımları ile fark yaratıyorlar.",
            rating: 5
        },
        {
            name: "J. S.",
            company: "Innovation Labs UK",
            role: "Technical Director",
            text: "Working with NEOPLUS SOFTWARE has been an outstanding experience. Their technical expertise and innovative solutions have significantly improved our business processes.",
            rating: 5
        },
        {
            name: "M. A.",
            company: "Al Fahim Technologies",
            role: "Managing Director",
            text: "The collaboration with NEOPLUS SOFTWARE exceeded our expectations. Their understanding of regional market needs and technical capabilities is impressive.",
            rating: 5
        },
        {
            name: "S. P.",
            company: "Global Tech Solutions",
            role: "Project Manager",
            text: "NEOPLUS SOFTWARE's approach to project management and technical execution is exemplary. They delivered a complex system that perfectly matches our requirements.",
            rating: 5
        },
        {
            name: "R. K.",
            company: "Future Systems GmbH",
            role: "Head of Innovation",
            text: "The AI solutions provided by NEOPLUS SOFTWARE have transformed our business operations. Their expertise in cutting-edge technologies is remarkable.",
            rating: 5
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => 
                prev === testimonials.length - 1 ? 0 : prev + 1
            );
        }, 10000); // 10 saniye

        return () => clearInterval(timer);
    }, [testimonials.length]);

    const nextSlide = () => {
        setCurrentSlide(current => 
            current === testimonials.length - 1 ? 0 : current + 1
        );
    };

    const prevSlide = () => {
        setCurrentSlide(current => 
            current === 0 ? testimonials.length - 1 : current - 1
        );
    };

    return (
        <section id="testimonials" className="testimonials-section">
            <div className="testimonials-container">
                <h2 className="section-title">Müşteri Yorumları</h2>
                <div className="testimonials-slider">
                    <button className="slider-button prev" onClick={prevSlide}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15 18l-6-6 6-6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <button className="slider-button next" onClick={nextSlide}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9 18l6-6-6-6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    {testimonials.map((testimonial, index) => (
                        <div 
                            key={index}
                            className={`testimonial-card ${index === currentSlide ? 'active' : ''}`}
                        >
                            <div className="testimonial-content">
                                <div className="testimonial-text">
                                    <p>"{testimonial.text}"</p>
                                    <div className="testimonial-author">
                                        <h4>{testimonial.name}</h4>
                                        <p>{testimonial.role}</p>
                                        <p>{testimonial.company}</p>
                                    </div>
                                    <div className="rating">
                                        {[...Array(testimonial.rating)].map((_, i) => (
                                            <span key={i} className="star">★</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="slider-dots">
                        {testimonials.map((_, index) => (
                            <button
                                key={index}
                                className={`dot ${index === currentSlide ? 'active' : ''}`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials; 