import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import AboutSection from './components/AboutSection';
import Services from './components/Services';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import './App.css';

function App() {
    const { t } = useTranslation();

    return (
        <div className="dark-mode">
            <Navbar />
            <div className="video-background">
                <video autoPlay loop muted playsInline>
                    <source src={process.env.PUBLIC_URL + '/assets/bg-video.mp4'} type="video/mp4" />
                </video>
            </div>
            <main className="main-content">
                <div className="content-box">
                    <h1 className="neon-title">
                        <span className="neoplus-text">NEOPLUS SOFTWARE</span>
                    </h1>
                    <h2 className="subtitle">{t('hero.subtitle')}</h2>
                    <h2 className="large-text">
                        {t('hero.title')}
                    </h2>
                    <p className="hero-description">
                        {t('hero.description')}
                    </p>
                    <button className="gradient-button">
                        {t('hero.button')}
                    </button>
                </div>
            </main>
            <section id="about" className="about-section">
                <AboutSection />
            </section>
            <Services />
            <Testimonials />
            <Contact />
        </div>
    );
}

export default App; 